import React, { useEffect, useState } from 'react';
import axios from "axios";

const RegStatistic = () => {
  const [userCounts, setUserCounts] = useState([]);

  useEffect(() => {
    // Fetch the data from the API
    axios
      .get("https://mxm2896.uta.cloud/urmconnect_php/admin.php")
      .then((response) => {
        // Filter and extract the user counts for the four roles
        const filteredCounts = response.data.filter(
          (item) =>
            item.role === "academia" ||
            item.role === "urmcandidate" ||
            item.role === "dei" ||
            item.role === "recruiter"
        );
        setUserCounts(filteredCounts);
      })
      .catch((error) => {
        console.error("Error fetching user counts:", error);
      });
  }, []);

  return (
    <div>
      <div className="new_jobposting_info">
        {/* Display the user counts for each role */}
        {userCounts.map((item) => (
          <div key={item.role}>
            Total number of Registered {item.role}:
            <br />
            {item.user_count}
            <br /><br />
          </div>
        ))}
      </div>
      <div className="regstatfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default RegStatistic;
