// import React from "react";
// // importing Link from react-router-dom to navigate to 
// // different end points.
// import { Link } from "react-router-dom";

// const recruitereditprofile = () => {
//   return (
//     <div>
//     <div class="new_jobposting_container">
//         <h1>Recruiter Profile</h1>
//         <form  method="post">
//             <label for="Name">Name:</label>
//             <input type="text" id="Name" name="Name" required/>
//             <label for="Email">Email:</label>
//             <input type="text" id="Email" name="Email" required/>
//             <label for="recagency">Recruiting Agency:</label>
//             <input type="text" id="recagency" name="recagency" required/>
//             <label for="clientnm">Client Name:</label>
//             <input type="text" id="clientnm" name="clientnm" required/>
//             <input type="submit" value="Update"/>
//         </form>
//     </div>
//     <div class="footer">
//         <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
//     </div>
//     </div>
//   );
// };

// export default recruitereditprofile;


import React, { useState } from "react";

const RecruiterEditProfile = () => {
  const [name, setName] = useState("");
  const [agency, setAgency] = useState("");
  const [client, setClient] = useState("");
  const user_id = localStorage.getItem("user_id");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      description: "Recruiter with id" + user_id + "updated their profile.",
      name: name,
      positions: "",
      agency: agency,
      clients: client,
      user_id: user_id
    };

    const response = await fetch('https://mxm2896.uta.cloud/urmconnect_php/recruiter.php?user_id=' + user_id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      // The profile was updated successfully
      alert('Profile updated!');
    } else {
      // Something went wrong
      alert('Error updating profile.');
    }
  };

  return (
    <div>
      <div class="new_jobposting_container">
        <h1>Recruiter Profile</h1>
        <form onSubmit={handleSubmit}>
          <label for="Name">Name:</label>
          <input type="text" id="Name" name="Name" required onChange={e => setName(e.target.value)} />

          <label for="recagency">Recruiting Agency:</label>
          <input type="text" id="recagency" name="recagency" required onChange={e => setAgency(e.target.value)} />
          <label for="clientnm">Client Name:</label>
          <input type="text" id="clientnm" name="clientnm" required onChange={e => setClient(e.target.value)} />
          <input type="submit" value="Update" />
        </form>
      </div>
      <div class="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default RecruiterEditProfile;
