
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function ChatDashboard() {
    const [users, setUsers] = useState([]);
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        // Fetch the users data from the API
        fetch('https://mxm2896.uta.cloud/urmconnect_php/custom_user.php')
            .then((response) => response.json())
            .then((data) => setUsers(data));
    }, []);

    return (
        <div>
            <div className="chatdash">
                <h1>Search who you want to chat with..</h1>
                <input className="searchforcand" type="text" />
                <br /><br />
                <ul className="user-list">
                    {users
                        .filter((user) => user.user_id !== userId) // Filter out the logged-in user
                        .map((user) => (
                            <li key={user.user_id}>
                                <div className="user-info">
                                    <span className="user-name">{user.firstname}</span>
                                    <span className="user-role">Role: {user.role}</span>
                                </div>
                                <Link to={`/chat/${user.user_id}`}>
                                    <button className="message-button">Message</button>
                                </Link>
                            </li>
                        ))}
                </ul>

            </div>
            <div className="chatdashfooter">
                <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default ChatDashboard;
