import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CandidateDashboard = () => {
  const user_id = localStorage.getItem("user_id");
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    if (user_id) {
      fetchCandidateData(user_id);
      fetchAppliedJobs(user_id);
    }
  }, [user_id]);

  const fetchAppliedJobs = async (user_id) => {
    try {
      const response = await fetch(
        `https://mxm2896.uta.cloud/urmconnect_php/job_posting.php?user_id=` + user_id
      );
      if (!response.ok) {
        throw new Error("Failed to fetch applied jobs data.");
      }
      const data = await response.json();
      setAppliedJobs(data);
    } catch (error) {
      console.error("Error fetching applied jobs data:", error);
    }
  };
  useEffect(() => {
    if (user_id) {
      fetchCandidateData(user_id);
    }
  }, [user_id]);

  const fetchCandidateData = async (user_id) => {
    try {
      const response = await fetch(
        `https://mxm2896.uta.cloud/urmconnect_php/urmcandidate.php?user_id=${user_id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch candidate data.");
      }
      const data = await response.json();
      setCandidate(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1 className="heading1">Candidate Dashboard</h1>
      <div className="content">
        <div className="left-side">
          <Link to="/candidateeditprof">
            <button className="profile">Edit Profile</button>
          </Link>
          <Link to="/candidatejobpost">
            <button className="profile">See New Job Roles</button>
          </Link>
          <h2>
            <p>Personal Information:</p>
          </h2>
          <div className="personalcontainer">
            <div className="info">
              <label>Name: {candidate[0]["name"] || "N/A"}</label>
            </div>
            <div className="info">
              <label>Education: {candidate[0]["education"] || "N/A"}</label>
            </div>
            <div className="info">
              <label>Resume:</label>
              {candidate[0]["resume"] === "null" ? (
                <span>No resume uploaded</span>
              ) : (
                <a href={candidate[0]["resume"]} target="_blank" rel="noopener noreferrer">
                  Download Resume
                </a>
              )}
            </div>
            <div className="info">
              <label>Location: {candidate[0]["location"] || "N/A"}</label>
            </div>
            <div className="info">
              <label>Ethnicity: {candidate[0]["ethnicity"] || "N/A"}</label>
            </div>
            <div className="info">
              <label>Field of Study: {candidate[0]["study_field"] || "N/A"}</label>
            </div>
            <div className="info">
              <label>Phone Number: {candidate[0]["phone"] || "N/A"}</label>
            </div>
            <div className="info">
              <label>Research Experience: {candidate[0]["research_experience"] || "N/A"}</label>
            </div>
            <div className="info">
              <label>CV:</label>
              {candidate[0]["cv"] === "null" ? (
                <span>No CV uploaded</span>
              ) : (
                <a href={candidate[0]["cv"]} target="_blank" rel="noopener noreferrer">
                  Download CV
                </a>
              )}
            </div>
            <div className="info">
              <label>Number of Publications: {candidate[0]["publications"] || "N/A"}</label>
            </div>
          </div>
        </div>
        <div className="right-side">
          <Link to="/chatdash">
            <button className="profile">Chat</button>
          </Link>
          <h2>
            <p>Applied Jobs</p>
          </h2>
          <div className="candjobpostinglist">
            {appliedJobs.map((job) => (
              <div className="job-posting" key={job.jid}>
                <h2>{job.job_description}</h2>
                <h4>{job.location}</h4>
                <h4>{job.location}</h4>
                <p>Status: {job.status}</p>

                <Link to={`/applicantdetails`}>
                  <button className="profile">Details</button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default CandidateDashboard;
