
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const DeiDashboard = () => {
    const user_id = localStorage.getItem("user_id");
    console.log("userid" + user_id);

    const [candidate, setCandidate] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [jobPostings, setJobPostings] = useState([]);
    const [customUser, setCustomUser] = useState({});
    const [deleteResponse, setDeleteResponse] = useState('');

    useEffect(() => {
        fetchCandidateData(user_id);
        fetchJobPostings();
        fetchCustomUserData(user_id);
    }, [user_id]);

    const fetchCandidateData = async (user_id) => {
        try {
            const response = await fetch(
                `https://mxm2896.uta.cloud/urmconnect_php/dei.php?user_id=${user_id}`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch candidate data.");
            }
            const data = await response.json();
            setCandidate(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const fetchJobPostings = async () => {
        try {
            const response = await fetch(
                `https://mxm2896.uta.cloud/urmconnect_php/job_posting.php`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch job postings data.");
            }
            const data = await response.json();
            setJobPostings(data);
        } catch (error) {
            console.error("Error fetching job postings data:", error);
        }
    };

    const fetchCustomUserData = async (user_id) => {
        try {
            const response = await fetch(
                `https://mxm2896.uta.cloud/urmconnect_php/custom_user.php?user_id=${user_id}`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch custom user data.");
            }
            const data = await response.json();
            setCustomUser(data);
        } catch (error) {
            console.error("Error fetching custom user data:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const candidateData = candidate || {};
    candidateData[0].role = "DEI Officer";
    const handleDelete = async (jobId) => {
        try {
            const response = await fetch(`https://mxm2896.uta.cloud/urmconnect_php/job_posting.php?job_id=${jobId}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            setDeleteResponse(data.message);
        } catch (error) {
            console.error('Error:', error);
            setDeleteResponse('Error occurred while deleting the job posting.');
        }
    };

    return (
        <div>
            <h1 className="heading1">DEI Dashboard</h1>
            <div className="content">
                <div className="left-side">
                    <Link to="/deieditprof">
                        <button className="profile">Edit Profile</button>
                    </Link>
                    <Link to="/deireports">
                        <button className="deireportbtn">Reports</button>
                    </Link>
                    <h2>
                        <p>Personal Information</p>
                    </h2>
                    <div className="personalcontainer">
                        <div className="info">
                            <label>Name: {candidateData[0].firstname || "N/A"}</label>
                        </div>
                        <div className="info">
                            <label>Description of Role: {candidateData[1].description || "N/A"}</label>
                        </div>
                    </div>

                    <div className="customUserContainer">
                        <div className="info">
                            <label>Email: {candidateData[0].email || "N/A"}</label>
                        </div>
                        <div className="info">
                            <label>Role: {candidateData[0].role || "N/A"}</label>
                        </div>
                        {/* Add other fields from the response as needed */}
                        {/* <div className="info">
                            <label>Firstname: {customUserData.firstname || "N/A"}</label>
                        </div> */}
                    </div>
                </div>




                <div className="dei_right-side">
                    <Link to="/createjob">
                        <button className="profile">Create New JobPosting</button>
                    </Link>
                    <Link to="/chatdash">
                        <button className="chatbtn">Chat</button>
                    </Link>
                    <h2>List of Job Postings that Meet DEI Criteria:</h2>
                    <div className="jobpostinglist">
                        {jobPostings.map((job) => (
                            <div className="job-posting" key={job.jid}>
                                <h3>{job.job_description}</h3>
                                <p>Location: {job.location}</p>
                                <p>Position Type: {job.position_type}</p>
                                <p>Date: {job.date}</p>
                                <p>Total Applicants: {job.total_applicants}</p>
                                <Link to={`/candidateviewstatus/${job.jid}`}>
                                    <button className="apply-button">View Status</button>
                                </Link>
                                <Link to={`/applicantdetails/${job.jid}`}>
                                    <button className="details-button">Details</button>
                                </Link>
                                <Link to={`/updatejobposting/${job.jid}`}>
                                    <button className="update-button">Update</button>
                                </Link>
                                <button className="update-button" onClick={() => handleDelete(job.jid)}>
                                    Delete
                                </button>
                            </div>
                        ))}
                    </div>
                    {deleteResponse && <p>{deleteResponse}</p>}
                </div>
            </div>





            <div className="footer">
                <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
            </div>
        </div>
    );
};

export default DeiDashboard;

