import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const DeiEditProfile = () => {
  const [deigoals, setDeigoals] = useState("");
  const [roledescrip, setRoledescrip] = useState("");
  const [positionpref, setPositionpref] = useState("");
  const [locationpref, setLocationpref] = useState("");

  const location = useLocation();
  const userId = location.state?.userId;
  console.log("User id is " + userId);
  useEffect(() => {
    // This function will run after the component has been mounted.
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      deigoals,
      roledescrip,
      positionpref,
      locationpref,
    };

    const response = await fetch("http://mxm2896.uta.cloud/urmconnect_php/dei/?user_id=" + userId, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      alert("Profile updated successfully!");
    } else {
      alert("Failed to update profile.");
    }
  };

  return (
    <div>
      <h1>DEI Officer Profile</h1>
      <form method="post">
        <label htmlFor="deigoals">DEI Goals and Initiatives:</label>
        <input type="text" id="deigoals" name="deigoals" value={deigoals} onChange={(e) => setDeigoals(e.target.value)} required />
        <label htmlFor="roledescrip">Description of Role:</label>
        <input type="text" id="roledescrip" name="roledescrip" value={roledescrip} onChange={(e) => setRoledescrip(e.target.value)} required />
        <label htmlFor="positionpref">Position Preference:</label>
        <input type="text" id="positionpref" name="positionpref" value={positionpref} onChange={(e) => setPositionpref(e.target.value)} required />
        <label htmlFor="locationpref">Location Preference:</label>
        <input type="text" id="locationpref" name="locationpref" value={locationpref} onChange={(e) => setLocationpref(e.target.value)} required />
        <input type="submit" value="Update" onClick={handleSubmit} />
      </form>
    </div>
  );
};

export default DeiEditProfile;
