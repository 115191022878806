import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const details = () => {
  return (
    <div>
    <div class="new_jobposting_info">
        <h1>Details:</h1>
            <label for="jobName">Job Name:</label><br/><br/>
            <label for="jobDescrip">Job Description:</label><br/><br/>
            <label for="location">Location:</label><br/><br/>
            <label for="positionType">Position Type:</label><br/><br/>
    </div>
    <div class="detailsfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
    </div>
    </div>
  );
};
  
export default details;