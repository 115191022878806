// import React from "react";
// import { Link } from "react-router-dom";

// const user_id = localStorage.getItem("user_id");

// const Header = () => {
//     const handleLogout = () => {
//         // Clear the user_id from local storage on logout
//         localStorage.removeItem("user_id");
//         window.location.reload();
//         // Optionally, you can perform other logout-related actions here if needed
//     };

//     return (
//         <header>
//             <div className="logo">URM Application</div>
//             <nav>
//                 <ul>
//                     <li><Link to="/">Home</Link></li>
//                     <li><Link to="/contactus">Contact</Link></li>
//                     <li><Link to="/about">About</Link></li>
//                     <div className="dropdown">
//                         <li><Link to="/about">Services</Link></li>
//                         <div className="dropdown-content">
//                             <Link to="/academiaservice">Academia</Link>
//                             <Link to="/deiservice">DEI Officer</Link>
//                             <Link to="/candidateservice">URM Candidate</Link>
//                             <Link to="/recruiterservice">Recruiter</Link>
//                         </div>
//                     </div>
//                     {/* Conditionally show "Login | Register" or "Logout" based on user_id */}
//                     {!user_id ? (
//                         <li>
//                             <Link to="/LoginReg">Login | Register</Link>
//                         </li>
//                     ) : (
//                         <li>
//                             <Link to="/" onClick={handleLogout}>Logout</Link>
//                         </li>
//                     )}
//                     <li>
//                         <a href="http://rxk3672.uta.cloud/blog/uncategorized/urmconnect/">URMConnect Blog</a>
//                     </li>
//                 </ul>
//             </nav>
//         </header>
//     );
// };

// export default Header;

import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    const user_id = localStorage.getItem("user_id");
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear the user_id from local storage on logout
        localStorage.removeItem("user_id");

        // Navigate to the home page after logout
        navigate("/");

        // Optionally, you can perform other logout-related actions here if needed
    };

    return (
        <header>
            <div className="logo">URM Application</div>
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/contactus">Contact</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <div className="dropdown">
                        <li><Link to="/about">Services</Link></li>
                        <div className="dropdown-content">
                            <Link to="/academiaservice">Academia</Link>
                            <Link to="/deiservice">DEI Officer</Link>
                            <Link to="/candidateservice">URM Candidate</Link>
                            <Link to="/recruiterservice">Recruiter</Link>
                        </div>
                    </div>
                    {/* Conditionally show "Login | Register" or "Logout" based on user_id */}
                    {!user_id ? (
                        <li>
                            <Link to="/LoginReg">Login | Register</Link>
                        </li>
                    ) : (
                        <li>
                            <Link to="/" onClick={handleLogout}>Logout</Link>
                        </li>
                    )}
                    <li>
                        <a href="http://rxk3672.uta.cloud/blog/uncategorized/urmconnect/">URMConnect Blog</a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
