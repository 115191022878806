import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

const candidateservice = () => {
  return (
    <div>
      <h1> URM Candidate</h1>
      <div class="academia_info">
        <p>You have the option to search for job postings on our platform.
          After completing the registration process, you have access to communicate with possible applicants who are considering your open opportunities.
          To make the hiring process easier and more effective, you will have the convenience of viewing job postings from recruiter, DEI Officer, Academia.
          In addition to the functions described above, our platform gives you the power to contact the recruters.
          We recognize the value of constructive communication during the recruiting process, and this option enables you to share insightful information with candidates, creating an open and fruitful dialogue for all parties.</p>
        <br /><br /><Link to="/candidateregister"><input type="submit" value="Register" /></Link>
      </div>
      <div class="academiaservicefooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default candidateservice;