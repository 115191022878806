
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const RecruiterDashboard = () => {
    const user_id = localStorage.getItem("user_id");
    const [recruiterData, setRecruiterData] = useState(null);
    const [personalData, setPersonalData] = useState(null);
    const [jobPostings, setJobPostings] = useState([]);
    const [position, setPosition] = useState("");
    const [location, setLocation] = useState("");
    const [deleteResponse, setDeleteResponse] = useState("");
    const [matchedCandidates, setMatchedCandidates] = useState([]);


    const apiUrl = "https://mxm2896.uta.cloud/urmconnect_php/recruiter.php?user_id=" + user_id;
    const personalApiUrl = "https://mxm2896.uta.cloud/urmconnect_php/custom_user.php?user_id=" + user_id;
    const jobPostingApiUrl = "https://mxm2896.uta.cloud/urmconnect_php/job_posting.php?user_id=" + user_id;

    const fetchData = async () => {
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            setRecruiterData(data[0]);

            const personalResponse = await fetch(personalApiUrl);
            const personalData = await personalResponse.json();
            setPersonalData(personalData[0]);

            const jobPostingsResponse = await fetch(jobPostingApiUrl);
            const jobPostingsData = await jobPostingsResponse.json();
            setJobPostings(jobPostingsData);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleSeeMatchedCandidates = async () => {
        try {
            setMatchedCandidates([]); // Reset the state to show "No matched candidates found." message
            const response = await fetch("http://mxm2896.uta.cloud/urmconnect_php/applied_candidates.php");
            const data = await response.json();
            setMatchedCandidates(data);
        } catch (error) {
            console.error("Error fetching matched candidates:", error);
            setMatchedCandidates([]);
        }
    };


    useEffect(() => {
        fetchData();
        handleSeeMatchedCandidates(); // Fetch the matched candidates from the "applied_candidates" API

    }, []);

    const handleDelete = async (jobId) => {
        try {
            const response = await fetch(`https://mxm2896.uta.cloud/urmconnect_php/job_posting.php?job_id=${jobId}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            setDeleteResponse(data.message);
            // Refresh the job postings after successful deletion
            fetchData();
        } catch (error) {
            console.error('Error:', error);
            setDeleteResponse('Error occurred while deleting the job posting.');
        }
    };




    return (
        <div>
            <h1 className="heading1">Recruiter Dashboard</h1>
            <div className="content">
                <div className="left-side">
                    <Link to="/recruitereditprofile">
                        <button className="profile">Edit Profile</button>
                    </Link>

                    {recruiterData && personalData && (
                        <div>
                            <h2>
                                <p>Personal Information</p>
                            </h2>
                            <div className="personalcontainer">
                                <div className="info">
                                    <label>Name:</label>
                                    <span>{personalData.firstname}</span>
                                </div>
                                <div className="info">
                                    <label>Email:</label>
                                    <span>{personalData.email}</span>
                                </div>
                                <div className="info">
                                    <label>Recruiting Agency:</label>
                                    <span>{recruiterData.agency}</span>
                                </div>
                                <div className="info">
                                    <label>Client Name:</label>
                                    <span>{recruiterData.clients}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    <br />

                    <br /><br />
                    <h2>Matched Candidates</h2>
                    <button className="recruiterapproval">Approve/Reject</button>





                    <table className="mytable">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Position</th>
                                <th>Job Role Applied</th>
                                <th>Approve</th>
                                <th>Reject</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matchedCandidates.map((candidate) => (
                                <tr key={candidate.appid}>
                                    <td>{candidate.firstname}</td>
                                    <td>{candidate.location}</td>
                                    <td>{candidate.position_type}</td>
                                    <td>{candidate.job_role}</td>
                                    <td>
                                        {/* Add approve functionality here */}
                                        <button className="approve-button">Approve</button>
                                    </td>
                                    <td>
                                        {/* Add reject functionality here */}
                                        <button className="reject-button">Reject</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>






                </div>


                <div className="recruiter_right-side">
                    <Link to="/createjob"><button className="createjob-button">Create New JobPosting</button></Link>
                    <Link to="/chat_dashboard"><button className="chatbtn">Chat</button></Link>
                    <h4>No of Job Postings: {jobPostings.length}</h4>
                    <div className="recruiterjobpostinglist">
                        {jobPostings.map((job, index) => (
                            <div key={index} className="job-posting">
                                <h3>Job Title: {job.job_description}</h3>
                                <h4>No of Candidates Applied: {job.applicants_no}</h4>
                                <Link to="/see_all_applicants"><button className="apply-button">See all Applicants</button></Link>
                                <Link to="/details"><button className="details-button">Details</button></Link>
                                <Link to="/update_job_posting"><button className="update-button">Update</button></Link>
                                <button className="delete-button" onClick={() => handleDelete(job.jid)}>Delete</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <div className="footer">
                <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default RecruiterDashboard;
