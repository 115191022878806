import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function AcademiaDashboard() {
    const [academia, setAcademia] = useState(null);
    const location = useLocation();
    const userId = location.state?.userId;
    const [jobPostings, setJobPostings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAcademiaData = async (userId) => {
            try {
                const response = await fetch(`https://mxm2896.uta.cloud/urmconnect_php/academia.php?user_id=${userId}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch academia data.");
                }
                const data = await response.json();
                setAcademia(data[0]);
            } catch (error) {
                console.error(error);
            }
        };
        if (userId) {
            fetchAcademiaData(userId);
        }
    }, [userId]);

    useEffect(() => {
        const fetchJobPostings = async () => {
            try {
                const response = await fetch('https://mxm2896.uta.cloud/urmconnect_php/job_posting.php');
                if (!response.ok) {
                    throw new Error("Failed to fetch job postings.");
                }
                const data = await response.json();
                setJobPostings(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setError("Failed to fetch job postings.");
                setLoading(false);
            }
        };
        fetchJobPostings();
    }, []);

    return (
        <div>
            <h1 className="heading1">Academia Dashboard</h1>
            <div className="content">
                <div className="left-side">
                    <Link to="/academiaeditprofile"><button className="profile">Edit Profile</button></Link>
                    <Link to="/bookmrkdcand"><button className="bookcand">Bookmarked Candidates</button></Link>
                    <Link to="/findbkcand"><button className="findbookcand">Find and Bookmark Candidates</button></Link>
                    <h2><p>Personal Information</p></h2>
                    <div className="personalcontainer">
                        <div className="info">
                            <label>Name:</label>
                            <p>{academia?.name}</p>
                        </div>
                        <div className="info">
                            <label>Description:</label>
                            <p>{academia?.description}</p>
                        </div>
                        <div className="info">
                            <label>Research Focus:</label>
                            <p>{academia?.research_focus}</p>
                        </div>
                        <div className="info">
                            <label>Positions Offered:</label>
                            <p>{academia?.positions}</p>
                        </div>
                    </div>
                    <br />

                </div>
                <div className="right-side">
                    <div className="acacandjobpostinglist">
                        {loading ? (
                            <p>Loading job postings...</p>
                        ) : error ? (
                            <p>Error: {error}</p>
                        ) : jobPostings.length === 0 ? (
                            <p>No job postings available.</p>
                        ) : (
                            // Job postings
                            jobPostings.map(job => (
                                <div className="job-posting" key={job.jid}>
                                    <h3>{job.job_description}</h3>
                                    <p>Location: {job.location}</p>
                                    <p>Position Type: {job.position_type}</p>
                                    <p>Date: {job.date}</p>
                                    <h4>No of Candidates Applied: {job.total_applicants}</h4>
                                    <Link to={`/applied_candidates`}>
                                        <button className="details-button">Show Applied Candidates</button>
                                    </Link>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <div className="footer">
                <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default AcademiaDashboard;