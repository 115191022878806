import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const chatbox = () => {
  return (
    <div>
    <div class="chatcontainer">
        <div class="chatheader">
            <h1>Chat</h1>
        </div>
        <div class="chatmessages">
            <div class="message incoming">
                <p>John: Hello there!</p>
            </div>
            <div class="message outgoing">
                <p>You: Hi John!</p>
            </div>
        
        </div>
        <div class="chatinput">
            <input type="text" placeholder="Type your message..."/>
            <button>Send</button>
        </div>
    </div>
    <div class="chatfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
    </div>
    </div>
  );
};
  
export default chatbox;