import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import heroImage from '../images/TA_V2.png';
import { Link } from "react-router-dom";
  
const homepage = () => {
  return (
    <div>
   
    <p><img alt="Hero GIF" className="hero-gif" src={heroImage} /></p>
<div class="hero">
<h1 class="heading">Empowering Diversity in Academia</h1>
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
&nbsp;
<p class="tagline">Welcome to URM Application - Where Creativity and Diversity Thrive!</p>
<a class="cta-button" href="#about">Learn More</a><br />
<br />
&nbsp;</div>

<div class="aboutcontainer" id="about">
<div class="feature-box">
<div class="feature-icon"></div>

<h3 class="feature-title">Global Reach</h3>

<p class="feature-description">Our platform is global in scope, allowing applicants and institutions from all backgrounds to collaborate and build a better academic community.</p>
</div>

<div class="feature-box">
<div class="feature-icon"></div>

<h3 class="feature-title">Meet Our Team</h3>

<p class="feature-description">Our team consists of enthusiastic and devoted individuals that believe in the potential of diversity. Meet the geniuses behind URM Application.</p>
<a class="cta-button" href="#team">Meet The Team</a></div>

<div class="feature-box">
<div class="feature-icon"></div>

<h3 class="feature-title">Powerful Search Capabilities</h3>

<p class="feature-description">Our platform has extensive search options that enable institutions to locate the best URM applicants and individuals to explore personalized possibilities.</p>
</div>
</div>

<div class="teamcontainer" id="team">
<h2 class="heading">Meet Our Team</h2>

<div class="team-members">
<div class="team-member"><img alt="Rajat Kulkarni" src="rajat_kulkarni.jpg" />
<h3>Rajat Kulkarni</h3>
</div>

<div class="team-member"><img alt="Dhanoosh" src="dhanoosh.jpg" />
<h3>Dhanoosh</h3>
</div>

<div class="team-member"><img alt="Naveen" src="naveen.jpg" />
<h3>Naveen</h3>
</div>

<div class="team-member"><img alt="Meghana" src="meghana.jpg" />
<h3>Meghana</h3>
</div>

<div class="team-member"><img alt="Teja" src="teja.jpg" />
<h3>Teja</h3>
</div>
</div>
</div>

<div class="footer">
<p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
</div>
</div>
  );
};
  
export default homepage;