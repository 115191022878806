import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const forgotpassword = () => {
  return (
    <div>
    <div class="forgotpasswordcontainer">
        <h1>Forgot Password</h1>
        <form action="forgotemail.html" method="post">
            <label for="forgotemail">Email:</label>
            <input type="text" id="forgotemail" name="forgotemail" required/>
            <Link to="/forgotemail"><input type="submit" value="Reset Password"/></Link>
            
        </form>
    </div>
    </div>
  );
};
  
export default forgotpassword;