import React, { useState, useEffect } from 'react';

function ChatPage() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // New state for loading status
    const userId = localStorage.getItem('user_id');
    const receiverIdFromURL = window.location.pathname.split('/').pop();

    useEffect(() => {
        // Fetch the user information for the logged-in user
        fetch(`https://mxm2896.uta.cloud/urmconnect_php/custom_user.php?user_id=${userId}`)
            .then((response) => response.json())
            .then((data) => {
                setSelectedUser(data);
                setIsLoading(false); // Set loading to false once user information is fetched
            })
            .catch((error) => {
                console.error('Error fetching user information:', error);
                setIsLoading(false); // Set loading to false in case of an error
            });
    }, [userId]);

    useEffect(() => {
        // Fetch chat messages whenever the receiverIdFromURL changes and selectedUser is available
        if (selectedUser) {
            fetchChatMessages();
        }
    }, [selectedUser, receiverIdFromURL]);

    const fetchChatMessages = () => {
        if (!selectedUser || !receiverIdFromURL) return;

        fetch(`https://mxm2896.uta.cloud/urmconnect_php/chat_messages.php?user_id=${userId}&receiver_id=${receiverIdFromURL}`)
            .then((response) => response.json())
            .then((data) => {
                console.log('Fetched chat messages:', data);
                setChatMessages(data.data); // Update chatMessages state with the data array
            })
            .catch((error) => {
                console.error('Error fetching chat messages:', error);
                setChatMessages([]);
            });
    };


    const handleSendMessage = () => {
        if (!selectedUser) {
            console.error('No selected user');
            return;
        }

        const requestData = {
            user_id: userId,
            receiver_id: receiverIdFromURL,
            message_text: messageText,
        };

        console.log('Sending message with data:', requestData);

        fetch('https://mxm2896.uta.cloud/urmconnect_php/chat.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Received response data:', data);
                // Handle the response as needed
                // Clear the message text after sending the message
                setMessageText('');
                // Fetch the updated chat messages after sending the message
                fetchChatMessages();
            })
            .catch((error) => console.error('Error sending message:', error));
    };

    return (
        <div>
            <h1>Chat Page</h1>
            {isLoading ? ( // Show loading status
                <p>Loading user information...</p>
            ) : selectedUser ? (
                <div>
                    <h2>Chatting with: {selectedUser.firstname}</h2>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                        {/* Display chat messages */}
                        {chatMessages.length > 0 ? (
                            chatMessages.map((message) => (
                                <div
                                    key={message.message_id}
                                    style={{
                                        backgroundColor: message.sender_id === Number(userId) ? '#DCF8C6' : '#FFF',
                                        padding: '8px',
                                        borderRadius: '8px',
                                        maxWidth: '60%',
                                        alignSelf: message.sender_id === Number(userId) ? 'flex-end' : 'flex-start',
                                    }}
                                >
                                    <p>{message.message_text}</p>
                                    <p style={{ fontSize: '12px', color: '#999' }}>Sent at: {message.sent_at}</p>
                                </div>
                            ))
                        ) : (
                            <p>No chat messages available.</p>
                        )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                        <input
                            type="text"
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            placeholder="Type your message..."
                            style={{ flex: 1, padding: '8px', borderRadius: '8px', border: '1px solid #999' }}
                        />
                        <button
                            onClick={handleSendMessage}
                            style={{
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '8px',
                                cursor: 'pointer',
                            }}
                        >
                            Send
                        </button>
                    </div>
                </div>
            ) : (
                <p>No user selected.</p>
            )}
        </div>
    );
}

export default ChatPage;
