import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const feedback = () => {
  return (
    <div>
    <table id="customers">
  <tr>
    <th>Serial Number</th>
    <th>Feedback/Review</th>
    <th>Role(URM Candidate/Academia)</th>
    <th>View more </th>
    <th>Chat with the user</th>
  </tr>
  <tr>
    <td>1</td>
    <td>Error</td>
    <td>URM Candidate</td>
    <td>-- </td>
    <td><Link to="/chatdash"><button class="chatbtn">Chat</button></Link></td>
  </tr>
  <tr>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  
</table>
<div class="manageuserfooter">
  <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
</div>
</div> 
  );
};
  
export default feedback;