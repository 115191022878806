import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // New state variable

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create the POST request data object
    const postData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };

    // Make the POST request to the API endpoint
    fetch('https://mxm2896.uta.cloud/urmconnect_php/form_submissions.php', postData)
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here, if needed
        console.log(data);
        setIsSubmitted(true); // Set the submission status to true
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      {/* <header>
        <div className="logo">URM Application</div>
        <nav>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/contactus">Contact</a></li>
            <li><a href="/aboutus">About</a></li>
            <li></li>
            <li><a href="/login_options">Login</a></li>
            <li><a href="http://rxk3672.uta.cloud/urmconnect/">URMConnect Blog</a></li>
          </ul>
        </nav>
      </header> */}

      <div className="contactcontainer">
        <h1 className="heading">Contact Us</h1>
        {!isSubmitted ? ( // Render the form only if it's not submitted
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            <input type="submit" value="Submit" />
          </form>
        ) : (
          <p>Form is successfully submitted</p> // Show the message if the form is submitted
        )}
        <div className="contact-info">
          <p>
            Connect with us on LinkedIn:{' '}
            <a
              href="https://www.linkedin.com/company/your-company-name"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn Profile
            </a>
          </p>
          <p>
            Call us at: <a href="tel:+1234567890">+1 (234) 567-890</a>
          </p>
        </div>
      </div>

      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Contact;
