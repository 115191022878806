import React, { useState, useEffect } from "react";

const AppliedCandidates = () => {
    const [candidates, setCandidates] = useState([]);

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const response = await fetch("http://mxm2896.uta.cloud/urmconnect_php/applied_candidates.php");
                const data = await response.json();
                setCandidates(data);
            } catch (error) {
                console.error("Error fetching candidates:", error);
                setCandidates([]);
            }
        };

        fetchCandidates();
    }, []);

    return (
        <div>
            <h2>Applied Candidates</h2>
            <table className="mytable">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Position</th>
                        <th>Job Role Applied</th>
                    </tr>
                </thead>
                <tbody>
                    {candidates.map((candidate) => (
                        <tr key={candidate.appid}>
                            <td>{candidate.firstname}</td>
                            <td>{candidate.location}</td>
                            <td>{candidate.position_type}</td>
                            <td>{candidate.job_role}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AppliedCandidates;
