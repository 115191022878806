import React, { useEffect, useState } from "react";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://mxm2896.uta.cloud/urmconnect_php/custom_user.php")
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <div className="mgnusercontainer">
        <button className="mgnuserbutton">URM Candidates</button>
        <button className="mgnuserbutton">Recruiters</button>
        <button className="mgnuserbutton">DEI Officers</button>
        <button className="mgnuserbutton">Academia</button>
      </div>
      <br />
      <br />
      <table id="customers">
        <thead>
          <tr>
            <th>Serial Number:</th>
            <th>Name:</th>
            <th>Email:</th>
            <th>Role:</th>
            <th>Is Verified:</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.user_id}</td>
              <td>{user.firstname}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{user.is_verified === "1" ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="manageuserfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default ManageUsers;
