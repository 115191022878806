import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const adminstatusapp = () => {
  return (
    <div>
    <div class="new_jobposting_info">

    <input type="text" placeholder="Search.." name="search"/>
    <input type="submit" value="Search"></input>

    <table>
   <tr> URM Candidate Name:<br/><br/><br/></tr>
    <tr>Job posting:<br/><br/><br/></tr>
    <tr>Academia:<br/></tr>
</table>

    <div class="roadmap">
      
        <div class="milestone done">
            <div class="circle"></div>
            <div class="label">Applied on:</div>
        </div>
        <div class="milestone done">
            <div class="circle"></div>
            <div class="label">Reviewed on:</div>
        </div>
        <div class="milestone">
            <div class="circle"></div>
            <div class="label">Result:</div>
        </div>
    </div>
  </div>

  <div class="statusfooter">
    <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
</div>
</div>
  );
};
  
export default adminstatusapp;