import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

const candidatejobdetails = () => {

  return (
    <div>
      <div class="new_jobposting_container">
        <h1>Job Posting Details</h1>
        <form method="post">
          <label for="jobName">Job Name:</label>

          <label for="jobDescrip">Job Description:</label>

          <label for="location">Location:</label>

          <label for="positionType">Position Type:</label>

          <label for="positioncreatedby">Position Created By:</label>

          <input type="submit" value="Apply" />
        </form>
      </div>
      <div class="candidate_jobpostdetailsfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default candidatejobdetails;