import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

const matchesmade = () => {
  return (
    <div>
      <h2>Total no. of matches made so far: </h2>
      <table id="customers">
        <tr>
          <th>Serial Number</th>
          <th>Name job posting</th>
          <th>Posted by</th>
          <th>Number of applicants applied </th>
          <th>Date of job posting</th>
          <th>No of applicants matched</th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

      </table>
      <div class="matchesfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default matchesmade;