import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const CreateJob = () => {

    const [responseMessage, setResponseMessage] = useState("");

    const userId = localStorage.getItem("user_id");
    const location = useLocation();
    console.log("userid" + userId);
    const currentDate = new Date().toISOString().split('T')[0];
    console.log("User id - " + userId);
    const [jobData, setJobData] = useState({
        job_description: "",
        location: "",
        position_type: "",
        aid: 0,
        did: 0,
        applicants_no: 0,
        user_id: userId,
        date: currentDate,
        user_id: userId
    });


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setJobData((prevJobData) => ({
            ...prevJobData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        postJobDataToServer(jobData, userId);
    };

    const postJobDataToServer = (jobData, userId) => {

        // Add the userId as a query parameter to the API endpoint
        const apiUrl = `https://mxm2896.uta.cloud/urmconnect_php/job_posting.php?user_id=${userId}`;
        fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(jobData),
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the server if needed.
                console.log(data);

                // Assuming the server sends a response with a "message" field
                setResponseMessage(data.message || "Job posted successfully!"); // Display server response or a default message
            })
            .catch((error) => {
                // Handle any errors that occurred during the API call.
                console.error("Error sending job data:", error);
            });
    };
    return (
        <div className="createjob">
            <div className="new_jobposting_container">
                <h1>New Job Posting</h1>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="jobName">Job Name:</label>
                    <input
                        type="text"
                        id="jobName"
                        name="jobName"
                        value={jobData.jobName}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="job_description">Job Description:</label>
                    <input
                        type="text"
                        id="job_description"
                        name="job_description"
                        value={jobData.job_description}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="location">Location:</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={jobData.location}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="position_type">Job Position Type:</label>
                    <select
                        id="position_type"
                        name="position_type"
                        value={jobData.position_type}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Position Type</option>
                        <option value="fulltime">Full-Time</option>
                        <option value="parttime">Part-Time</option>
                        <option value="Post Doc">Contract</option>
                        <option value="Faculty">Internship</option>
                        <option value="temporary">Temporary</option>
                    </select>
                    <label htmlFor="PositionCreatedby">Position Created By:</label>
                    <select
                        id="Positioncreatedby"
                        name="Positioncreatedby"
                        value={jobData.Positioncreatedby}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Created By</option>
                        <option value="Academia">Academia</option>
                        <option value="DEI Officer">DEI Officer</option>
                        <option value="Recruiter">Recruiter</option>
                    </select>

                    <input type="submit" value="Register" />
                    {responseMessage && (
                        <div className="response-message">
                            <p>{responseMessage}</p>
                        </div>
                    )}
                </form>
            </div>
            <div className="footer">
                <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
            </div>
        </div>
    );
};

export default CreateJob;
