import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const MatchesMade = () => {
  const [matchesData, setMatchesData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://mxm2896.uta.cloud/urmconnect_php/job_posting.php")
      .then((response) => response.json())
      .then((data) => setMatchesData(data));
  }, []);

  return (
    <div>
      <h2>Total no. of matches made so far: {matchesData.length}</h2>
      <table id="customers">
        <tr>
          <th>Serial Number</th>
          <th>Job Description</th>
          <th>Location</th>
          <th>Position Type</th>
          <th>Date of Job Posting</th>
          <th>No of Applicants Applied</th>
          <th>No of Applicants Matched</th>
        </tr>
        {matchesData.map((match, index) => (
          <tr key={match.jid}>
            <td>{index + 1}</td>
            <td>{match.job_description}</td>
            <td>{match.location}</td>
            <td>{match.position_type}</td>
            <td>{match.date}</td>
            <td>{match.total_applicants}</td>
            <td>{match.aid}</td>
          </tr>
        ))}
      </table>
      <div className="matchesfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default MatchesMade;
