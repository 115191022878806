import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const CandidateEditProfile = () => {
  const [formData, setFormData] = useState({
    user_id: 6614,
    name: "",
    positions_interested: "",
    education: "",
    resume: "",
    location: "",
    ethnicity: "",
    field_of_study: "",
    phone_number: "",
    research_experience: 0,
    cv: "",
    number_of_publications: 0,
  });
  const user_id = localStorage.getItem("user_id");

  const location = useLocation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const endpoint =
      "https://mxm2896.uta.cloud/urmconnect_php/urmcandidate.php?user_id=" + user_id;
    axios
      .put(endpoint, formData)
      .then((response) => {
        console.log("Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
  };

  return (
    <div>
      <div className="new_jobposting_container">
        <h1>Candidate Profile</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="positions_interested">Positions Interested:</label>
          <input
            type="text"
            id="positions_interested"
            name="positions_interested"
            value={formData.positions_interested}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="education">Education:</label>
          <input
            type="text"
            id="education"
            name="education"
            value={formData.education}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="resume">Resume:</label>
          <textarea
            id="resume"
            name="resume"
            value={formData.resume}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="ethnicity">Ethnicity:</label>
          <input
            type="text"
            id="ethnicity"
            name="ethnicity"
            value={formData.ethnicity}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="field_of_study">Field of Study:</label>
          <input
            type="text"
            id="field_of_study"
            name="field_of_study"
            value={formData.field_of_study}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="phone_number">Phone Number:</label>
          <input
            type="text"
            id="phone_number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="research_experience">Research Experience:</label>
          <input
            type="number"
            id="research_experience"
            name="research_experience"
            value={formData.research_experience}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="cv">CV:</label>
          <textarea
            id="cv"
            name="cv"
            value={formData.cv}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="number_of_publications">Number of Publications:</label>
          <input
            type="number"
            id="number_of_publications"
            name="number_of_publications"
            value={formData.number_of_publications}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <input type="submit" value="Update" />
        </form>
      </div>
      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default CandidateEditProfile;

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const CandidateEditProfile = ({ candidate }) => {
//   const user_id = localStorage.getItem("user_id");
//   const candidateData = candidate || {};

//   const [formData, setFormData] = useState({
//     user_id: user_id,
//     name: candidateData.name ?? "",
//     positions_interested: candidateData.positions_interested ?? "",
//     education: candidateData.education ?? "",
//     resume: candidateData.resume ?? "",
//     location: candidateData.location ?? "",
//     ethnicity: candidateData.ethnicity ?? "",
//     field_of_study: candidateData.study_field ?? "",
//     phone_number: candidateData.phone ?? "",
//     research_experience: candidateData.research_experience ?? "",
//     cv: candidateData.cv ?? "",
//     number_of_publications: candidateData.publications ?? "",
//   });

//   // Fetch data and populate the input fields
//   useEffect(() => {
//     axios
//       .get(`https://mxm2896.uta.cloud/urmconnect_php/urmcandidate.php?user_id=${user_id}`)
//       .then((response) => {
//         const { data } = response;
//         if (data && data.length > 0) {
//           setFormData((prevData) => ({
//             ...prevData,
//             name: data[0].name,
//             positions_interested: data[0].positions_interested,
//             education: data[0].education,
//             resume: data[0].resume,
//             location: data[0].location,
//             ethnicity: data[0].ethnicity,
//             field_of_study: data[0].field_of_study,
//             phone_number: data[0].phone_number,
//             research_experience: data[0].research_experience,
//             cv: data[0].cv,
//             number_of_publications: data[0].number_of_publications,
//           }));
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, [user_id]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const endpoint =
//       "https://mxm2896.uta.cloud/urmconnect_php/urmcandidate.php?user_id=" +
//       user_id;
//     axios
//       .put(endpoint, formData)
//       .then((response) => {
//         console.log("Profile updated successfully!");
//       })
//       .catch((error) => {
//         console.error("Error updating profile:", error);
//       });
//   };

//   return (
//     <div>
//       <div className="new_jobposting_container">
//         <h1>Candidate Profile</h1>
//         <form onSubmit={handleSubmit}>
//           <label htmlFor="name">Name:</label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="positions_interested">Positions Interested:</label>
//           <input
//             type="text"
//             id="positions_interested"
//             name="positions_interested"
//             value={formData.positions_interested}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="education">Education:</label>
//           <input
//             type="text"
//             id="education"
//             name="education"
//             value={formData.education}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="resume">Resume:</label>
//           <textarea
//             id="resume"
//             name="resume"
//             value={formData.resume}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="location">Location:</label>
//           <input
//             type="text"
//             id="location"
//             name="location"
//             value={formData.location}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="ethnicity">Ethnicity:</label>
//           <input
//             type="text"
//             id="ethnicity"
//             name="ethnicity"
//             value={formData.ethnicity}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="field_of_study">Field of Study:</label>
//           <input
//             type="text"
//             id="field_of_study"
//             name="field_of_study"
//             value={formData.field_of_study}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="phone_number">Phone Number:</label>
//           <input
//             type="text"
//             id="phone_number"
//             name="phone_number"
//             value={formData.phone_number}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="research_experience">Research Experience:</label>
//           <input
//             type="number"
//             id="research_experience"
//             name="research_experience"
//             value={formData.research_experience}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="cv">CV:</label>
//           <textarea
//             id="cv"
//             name="cv"
//             value={formData.cv}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <label htmlFor="number_of_publications">Number of Publications:</label>
//           <input
//             type="number"
//             id="number_of_publications"
//             name="number_of_publications"
//             value={formData.number_of_publications}
//             onChange={handleChange}
//           />
//           <br />
//           <br />
//           <input type="submit" value="Update" />
//         </form>
//       </div>
//       <div className="footer">
//         <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
//       </div>
//     </div>
//   );
// };

// export default CandidateEditProfile;
