import React, { useState } from "react";
import axios from 'axios'; // Make sure to install axios or use fetch

const RecruiterRegister = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    recagency: "",
    clientname: ""
  });
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prevState => ({ ...prevState, [name]: value }));
    if (name === "email") setIsEmailValid(validateEmail(value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isEmailValid) {
      alert("Invalid email address");
      return;
    }

    if (form.password !== form.password_confirmation) {
      alert("Passwords do not match");
      return;
    }

    if (form.password.length < 8) {
      alert("Password should be at least 8 characters long.");
      return;
    }


    if (form.password !== form.password_confirmation) {
      alert("Passwords do not match");
      return;
    }
    const endpoint = 'https://mxm2896.uta.cloud/urmconnect_php';
    const url = '/recruiter.php';

    axios.post(endpoint + url, form)
      .then((response) => {
        console.log("Recruiter registered successfully!", response);
      })
      .catch((error) => {
        console.error("An error occurred while registering the recruiter", error);
      });
  };
  return (
    <div>
      <div className="new_jobposting_container">
        <h1>Recruiter Registration</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={form.name} onChange={handleChange} required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={form.email} onChange={handleChange} required />
          {!isEmailValid && <p className="error">Invalid email address</p>}

          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" value={form.password} onChange={handleChange} required minLength="8" />

          <label htmlFor="password_confirmation">Confirm Password:</label>
          <input type="password" id="password_confirmation" name="password_confirmation" value={form.password_confirmation} onChange={handleChange} required minLength="8" />

          <label htmlFor="recagency">Recruiting Agency:</label>
          <input type="text" id="recagency" name="recagency" value={form.recagency} onChange={handleChange} required />

          <label htmlFor="clientname">Client Name:</label>
          <input type="text" id="clientname" name="clientname" value={form.clientname} onChange={handleChange} required />

          <input type="submit" value="Register" />
        </form>
      </div>
      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default RecruiterRegister;
