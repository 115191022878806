import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Modal from 'react-modal';

const LoginReg = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState(null); // Define the userId state
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    let formErrors = {};

    if (!credentials.email) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      formErrors.email = 'Email address is invalid';
    }

    if (!credentials.password) {
      formErrors.password = 'Password is required';
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    const endpoint = 'https://mxm2896.uta.cloud/urmconnect_php/login_auth.php';
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(endpoint, credentials);
        if (response.data.status === 1) {
          const role = response.data.role;

          const userIdFromResponse = response.data.user_id; // Corrected variable name and path
          localStorage.setItem('user_id', userIdFromResponse);

          switch (role) {
            case "urmcandidate":
              console.log("Navigating with userId:", userIdFromResponse);
              navigate('/Candidate', { state: { userId: userIdFromResponse } });
              break;
            case "dei":
              navigate('/DEI', { state: { userId: userIdFromResponse } });
              break;
            case "academia":
              navigate('/academia', { state: { userId: userIdFromResponse } });
              break;
            case "recruiter":
              navigate('/recruiter', { state: { userId: userIdFromResponse } });
              break;
            case "admin":
              navigate('/admindashboard', { state: { userId: userIdFromResponse } });
              break;
            default:
              break;
          }
          window.location.reload()
        } else {
          setErrors({ form: response.data.message });

        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setErrors({ form: 'Invalid email or password' });
        } else {
          setErrors({ form: error.response.data.message || 'Login failed' });
          console.error('Login failed', error);
        }
      }
    }
  };



  return (
    <div className="loginoptions">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="Email">Email:</label>
          <input type="text" id="Email" name="email" onChange={handleChange} required />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" onChange={handleChange} required />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>
        {errors.form && <div className="error">{errors.form}</div>}
        <div className="form-group">
          <input type="submit" value="Login" />
        </div>
        <RegistrationModal />

      </form>
    </div>
  );
};



const RegistrationModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <button className="register-btn" onClick={openModal}>Register</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="registration-modal"
        overlayClassName="modal-overlay"
      >
        <h2>Choose Registration Type</h2>
        <button type="submit"><Link to="/academiaservice">Academia</Link></button>
        <button><Link to="/deiservice">DEI Officer</Link></button>
        <button><Link to="/candidateservice">URM Candidate</Link></button>
        <button><Link to="/recruiterservice">Recruiter</Link></button>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};


export default LoginReg;