import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

const admindashboard = () => {
  return (
    <div>
      <h1>ADMIN Dashboard</h1>
      <table>

        <Link to="/regstatistic"><button class="adminbutton">View Registration Statastics</button></Link>
        <Link to="/placementstat"><button class="adminbutton">View Placement Statastics</button></Link>
        <Link to="/manageusers"><button class="adminbutton">Manage Users</button></Link>
        <Link to="/feedback"><button class="adminbutton">Feedback and Reviews</button></Link>
        <Link to="/matchesmade"><button class="adminbutton">Matches Made</button></Link>
        <Link to="/adminjobposting"><button class="adminbutton">Job postings</button></Link>

        <Link to="/adminstatusapp"><button class="adminbutton">Status of Applications</button></Link>

        <Link to="/monitoractivity"><button class="adminbutton">Graphs & Statistics</button></Link>
        <Link to="/chatdash"><button class="adminbutton">Chat</button></Link>


      </table>
      <div class="adminfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default admindashboard;