
import React from "react";
  
const About = () => {
  return (
    <div>
    <div class="aboutcontainer">
        <h1 class="heading">About Us</h1>
        <p class="description">The URM Application for Academia is a cutting-edge web-based platform created to support
            underrepresented minority (URM) candidates in engaging with academic institutions,
            recruiters, and DEI officials. It is intended to promote diversity, equity, and inclusion in
            academia. It provides a complete answer for doctoral work, postdoc opportunities, and
            academic posts. Academia can look for suitable URM candidates through a simple interface,
            see through profiles, and communicate effectively. Candidates with URMs can develop
            engaging profiles, highlight their qualifications, and look into opportunities that fit them. To
            encourage recruiting practices that prioritise diversity, recruiters and DEI officials can
            actively participate in the platform. The URM Application for Academia seeks to close the
            representational gap and foster an inclusive academic community with its user-friendly
            design, powerful search capabilities, and specialised dashboards.</p>

        <h2 class="headingabout">Meet Our Team</h2>
        <div class="team-members">
            <div class="team-member">
                <img src="https://via.placeholder.com/150" alt="Team Member 1"/>
                <h3>Rajat Kulkarni</h3>
            </div>
            <div class="team-member">
                <img src="https://via.placeholder.com/150" alt="Team Member 1"/>
                <h3>Dhanoosh</h3>
            </div>
            <div class="team-member">
                <img src="https://via.placeholder.com/150" alt="Team Member 1"/>
                <h3>Naveen</h3>
            </div>
            <div class="team-member">
                <img src="https://via.placeholder.com/150" alt="Team Member 1"/>
                <h3>Meghana</h3>
            </div>
            <div class="team-member">
                <img src="https://via.placeholder.com/150" alt="Team Member 1"/>
                <h3>Teja</h3>
            </div>
        </div>
    </div>

    <div class="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
    </div>
    </div>
  );
};
  
export default About;
