import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const candidateviewstatus = () => {
  return (
    <div>
    <div class="new_jobposting_info">
    
    Job posting:<br/>

    <div class="roadmap">
      
        <div class="milestone done">
            <div class="circle"></div>
            <div class="label">Applied on:</div>
        </div>
        <div class="milestone done">
            <div class="circle"></div>
            <div class="label">Reviewed on:</div>
        </div>
        
        <div class="milestone">
            <div class="circle"></div>
            <div class="label">Result:</div>
        </div>
    </div>
  </div>

  <div class="statusfooter">
    <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
</div>
</div>

  );
};
  
export default candidateviewstatus;