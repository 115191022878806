import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

const academiaservice = () => {
  return (
    <div>
      <h1> Academia</h1>
      <div class="academia_info">
        <p>You have the option to easily post job details on our platform.
          After completing the registration process, you have access to communicate with possible applicants who are considering your open opportunities.
          To make the hiring process easier and more effective, you will have the convenience of examining their profiles and saving them for later use.
          In addition to the functions described above, our platform gives you the power to give feedback to the applicants you have contacted or recruited.
          We recognize the value of constructive communication during the recruiting process, and this option enables you to share insightful information with candidates, creating an open and fruitful dialogue for all parties.</p>
        <br /><br />
        <Link to="/academiaregister"><input type="submit" value="Register" /></Link>

      </div>
      <div class="academiaservicefooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default academiaservice;