import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

  
const updatejobposting = () => {
    const options = ['Fulltime', 'Parttime', 'Postdoc', 'Faculty', 'Temporary'];
    const onOptionChangeHandler = (event) => {
        console.log("User Selected Value - ", event.target.value)
    }
  return (
    <div>
    <div class="new_jobposting_container">
        <h1>Update Job Posting</h1>
        <form  method="post">
            <label for="jobName">Job Name:</label>
            <input type="text" id="jobName" name="jobName" required/>
            <label for="jobDescrip">Job Description:</label>
            <input type="text" id="jobDescrip" name="jobDescrip" required/>
            <label for="location">Location:</label>
            <input type="text" id="location" name="location" required/>
            <label for="positionType">Position Type:</label>
            <select onChange={onOptionChangeHandler}>
 
                    <option>Please choose one option</option>
                    {options.map((option, index) => {
                        return <option key={index} >
                            {option}
                        </option>
                    })}
                </select>
            {/* <select id="positionType" name="positionType" required/>
                <option value="fulltime">Full-Time</option>
                <option value="parttime">Part-Time</option>
                <option value="Post Doc">Contract</option>
                <option value="Faculty">Internship</option>
                <option value="temporary">Temporary</option>
            <select/> */}
            <label for="positioncreatedby">Position Created By:</label>
            <select onChange={onOptionChangeHandler}>
 
                    <option>Please choose one option</option>
                    {options.map((option, index) => {
                        return <option key={index} >
                            {option}
                        </option>
                    })}
                </select>
            {/* <label for="positioncreatedby">Position Created By:</label>
            <select id="positioncreatedby" name="positioncreatedby" required>
                <option value="Academia">Academia</option>
                <option value="DEI Officer">DEI Officer</option>
                <option value="Recruiter">Recruiter</option>
            </select> */}
            <input type="submit" value="Update"/>
        </form>
    </div>
    <div class="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
    </div>
    </div> 
  );
};
  
export default updatejobposting;