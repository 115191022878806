import React, { useState } from "react";
import axios from "axios";

const DEIRegister = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const [goals, setGoals] = useState("");
  const [description, setDescription] = useState("");
  const [positionPref, setPositionPref] = useState("");
  const [locationPref, setLocationPref] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
    setIsEmailValid(validateEmail(email));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      alert("Invalid email address");
      return;
    }

    if (password !== password_confirmation) {
      alert("Passwords do not match");
      return;
    }

    if (password.length < 8) {
      alert("Password should be at least 8 characters long.");
      return;
    }
    const endpoint = 'https://mxm2896.uta.cloud/urmconnect_php/';

    const url = endpoint + "/dei.php"; //
    const data = {
      name,
      email,
      password,
      password_confirmation,
      goals,
      description,
      position_pref: positionPref,
      location_pref: locationPref,
    };

    axios.post(url, data)
      .then((response) => {
        alert("Registration successful!");

        // You may want to redirect the user or do something else with the response
      })
      .catch((error) => {
        alert("An error occurred during registration.");
        console.error(error);
      });
  };

  return (
    <div>
      <div className="new_jobposting_container">
        <h1>DEI Officer Registration</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="Name">Name:</label>
          <input type="text" id="Name" name="Name" required onChange={e => setName(e.target.value)} />

          <label htmlFor="Email">Email:</label>
          <input type="email" id="Email" name="Email" required onChange={handleEmailChange} />
          {!isEmailValid && <p className="error">Invalid email address</p>}

          <label htmlFor="dei_pass">Password:</label>
          <input type="password" id="dei_pass" name="dei_pass" required minLength="8" onChange={e => setPassword(e.target.value)} />

          <label htmlFor="dei_confpass">Confirm Password:</label>
          <input type="password" id="dei_confpass" name="dei_confpass" required minLength="8" onChange={e => setpassword_confirmation(e.target.value)} />

          <label htmlFor="deigoals">DEI Goals and Initiatives:</label>
          <input type="text" id="deigoals" name="deigoals" required onChange={e => setGoals(e.target.value)} />

          <label htmlFor="roledescrip">Description of Role:</label>
          <input type="text" id="roledescrip" name="roledescrip" required onChange={e => setDescription(e.target.value)} />

          <label htmlFor="positionpref">Position Preference:</label>
          <input type="text" id="positionpref" name="positionpref" required onChange={e => setPositionPref(e.target.value)} />

          <label htmlFor="locationpref">Location Preference:</label>
          <input type="text" id="locationpref" name="locationpref" required onChange={e => setLocationPref(e.target.value)} />

          <input type="submit" value="Register" />
        </form>
      </div>
      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default DEIRegister;
