import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const placementstatistic = () => {
  return (
    <div>
    <h2>Below a graph showing the placement stastics would be displayed.
  Having month and year on X-axis and number of people on the Y-axis. Bar graphs each representing URM Candidate.</h2>


<div class="placementfooter">
  <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
</div>
</div>
    
  );
};
  
export default placementstatistic;