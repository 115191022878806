import logo from './logo.svg';
import './App.css';
import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,

} from "react-router-dom";

// import Home component
import Header from "./components/Header";
// import About component
import About from "./components/About";
import LoginReg from './components/LoginReg';
import Academichdash from './components/AcademiaDashboard';
import AcademiaEdit from './components/academiaeditprofile';
import BookmrkCand from './components/bookmarkedcand';
import FindBkCand from './components/findbookcand';
import CreateJb from './components/createjob';
import SeeAllapps from './components/seeallapplicants';
import ApplicantDetails from './components/details';
import ChatDash from './components/ChatDashboard';
import ChatBox from './components/chatbox';
import Candidatedash from './components/candidatedashboard';
import CandidateEditprof from './components/candidateeditfprofile';
import CandidateJobpost from './components/candidatejobpost';
import CandidateJobdetails from './components/candidatejobdetails';
import CandidateViewstatus from './components/candidateviewstatus';
import Deidashboard from './components/deidashboard';
import DeiEditprofile from './components/deieditprofile';
import DeiReports from './components/deireports';
import UpdateJobposting from './components/updatejobposting';
import Recruiterdash from './components/recruiterdashboard';
import RecruiterEditprofile from './components/recruitereditprofile';
import Contact from './components/contact';
import AcademiaService from './components/academiaservice';
import DeiService from './components/deiservice';
import CandidateService from './components/candidateservice';
import RecruiterService from './components/recruiterservice';
import AcademiaRegister from './components/academicregister';
import DeiRegister from './components/deiregister';
import RecruiterRegister from './components/recruiterregister';
import CandidateRegister from './components/candidateregister';
import AdminDashboard from './components/admindashboard';
import Forgotpassword from './components/forgotpassword';
import Forgotemail from './components/forgotemail';
import Regstatistic from './components/regstatistic';
import Placementstatistic from './components/placementstatistic';
import Manageusers from './components/manageusers';
import Feedback from './components/feedback';
import Matchesmade from './components/matchesmade';
import AdminJobposting from './components/adminjobposting';
import Adminstatusapp from './components/adminstatusapp';
import MonitorActivity from './components/monitoruseractivity';
import Homepage from './components/homepage';
import DropdownExample from './components/dropdown';
import AppliedCandidates from './components/applied_candidates';
import ChatPage from './components/ChatPage';

// import ContactUs component
import ContactUs from "./components/ContactUs";

function App() {
  return (
    <>

      {/* This is the alias of BrowserRouter i.e. Router */}
      <Router>

        <Header />

        <Routes>

          <Route path="/about" element={<About />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/LoginReg" element={<LoginReg />} />
          <Route path="/Academia" element={<Academichdash />} />
          <Route path="/AcademiaEditprofile" element={<AcademiaEdit />} />
          <Route path="/bookmrkdcand" element={<BookmrkCand />} />
          <Route path="/findbkcand" element={<FindBkCand />} />
          <Route path="/createjob" element={<CreateJb />} />
          <Route path="/seeallappli" element={<SeeAllapps />} />
          <Route path="/applicantdetails" element={<ApplicantDetails />} />
          <Route path="/chatdash" element={<ChatDash />} />
          <Route path="/chatbox" element={<ChatBox />} />
          <Route path="/Candidate" element={<Candidatedash />} />
          <Route path="/candidateeditprof" element={<CandidateEditprof />} />
          <Route path="/candidatejobpost" element={<CandidateJobpost />} />
          <Route path="/candidatejobdetails" element={<CandidateJobdetails />} />
          <Route path="/candidateviewstatus" element={<CandidateViewstatus />} />
          <Route path="/DEI" element={<Deidashboard />} />
          <Route path="/deieditprof" element={<DeiEditprofile />} />
          <Route path="/deireports" element={<DeiReports />} />
          <Route path="/updatejobposting" element={<UpdateJobposting />} />
          <Route path="/Recruiter" element={<Recruiterdash />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/recruitereditprofile" element={<RecruiterEditprofile />} />
          <Route path="/academiaservice" element={<AcademiaService />} />
          <Route path="/deiservice" element={<DeiService />} />
          <Route path="/candidateservice" element={<CandidateService />} />
          <Route path="/recruiterservice" element={<RecruiterService />} />
          <Route path="/academiaregister" element={<AcademiaRegister />} />
          <Route path="/deiregister" element={<DeiRegister />} />
          <Route path="/recruiterregister" element={<RecruiterRegister />} />
          <Route path="/candidateregister" element={<CandidateRegister />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/forgotpass" element={<Forgotpassword />} />
          <Route path="/forgotemail" element={<Forgotemail />} />
          <Route path="/regstatistic" element={<Regstatistic />} />
          <Route path="/placementstat" element={<Placementstatistic />} />
          <Route path="/manageusers" element={<Manageusers />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/matchesmade" element={<Matchesmade />} />
          <Route path="/adminjobposting" element={<AdminJobposting />} />
          <Route path="/adminstatusapp" element={<Adminstatusapp />} />
          <Route path="/monitoractivity" element={<MonitorActivity />} />
          <Route path="/applied_candidates" element={<AppliedCandidates />} />
          <Route path="/chat/:userId" element={<ChatPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
