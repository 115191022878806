import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const FindBookCand = () => {
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://mxm2896.uta.cloud/urmconnect_php/urmcandidate.php"
        );
        const data = await response.json();
        setCandidates(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleBookmark = (appId) => {
    // Implement the bookmark functionality here
    console.log("Bookmark candidate with appId:", appId);
  };

  return (
    <div>
      <h1>BookMark Any Candidates:</h1>
      <div className="searchcand">
        <input type="text" className="searchtextbox" /><br />
        <input type="submit" value="Search" />
      </div>
      <table className="applicanttable">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Positions Interested</th>
          <th>BookMark</th>
        </tr>
        {candidates.map((candidate) => (
          <tr key={candidate.appid}>
            <td>{candidate.firstname}</td>
            <td>{candidate.email}</td>
            <td>{candidate.phone}</td>
            <td>{candidate.position_type}</td>
            <td>
              <button
                onClick={() => handleBookmark(candidate.appid)}
                id={candidate.appid}
                className="bookmarkButton"
              >
                Bookmark
              </button>
            </td>
          </tr>
        ))}
      </table>
      <div className="candidate_jobpostdetailsfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default FindBookCand;
