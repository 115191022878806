import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const seeallapplicants = () => {
  return (
    <div>
    <h1>List of Applicants:</h1>
    <table class="applicanttable">
       <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th>Positions Interested</th>
        <th>Status</th>
      </tr>
      <tr>
        <td>John</td>
        <td>John@gmail.com</td>
        <td>+1 5757 995 8848</td>
        <td>PostDoc, Faculty</td>
        <td>In Progress</td>
        <td><a href="chat.html"><button class="chatbtn">Chat</button> </a></td>
      </tr>
    
    </table>
    <div class="candidate_jobpostdetailsfooter">
       <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
    </div>
    </div>
  );
};
  
export default seeallapplicants;