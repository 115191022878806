import React, { useState } from 'react';
import axios from 'axios';

const endpoint = 'https://mxm2896.uta.cloud/urmconnect_php/login_auth.php';

const CandidateRegister = () => {

  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    positions_interested: '', // Updated this line
    education: '',
    resume: null,
    location: '',
    ethnicity: '',
    field_of_study: '', // Updated this line
    phone_number: '', // Updated this line
    research_experience: '', // Updated this line
    cv: null,
    number_of_publications: '', // Updated this line
  });
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;


    if (name === 'email') {
      setIsEmailValid(validateEmail(value));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (form.password !== form.password_confirmation) {
      alert('Passwords do not match');
      return;
    }


    const formData = new FormData();

    console.log('Submitting form:', form);
    for (const key in form) {
      console.log('Appending:', key, form[key]);
      formData.append(key, form[key]);
    }



    axios.post('https://mxm2896.uta.cloud/urmconnect_php/urmcandidate.php', formData)
      .then(() => {
        alert('Registration successful!');
        // Redirect or show success message
      })
      .catch(error => {
        alert('Registration failed: ' + error.message);
        // Handle error, such as displaying a message to the user
      });
  };

  return (
    <div>
      <div className="new_jobposting_container">
        <h1>Candidate Registration</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="Name">Name:</label>
          <input type="text" id="Name" name="name" required onChange={handleChange} />

          <label htmlFor="Email">Email:</label>
          <input type="email" id="Email" name="email" required onChange={handleChange} />
          {!isEmailValid && <p className="error">Invalid email address</p>}

          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" required minLength="8" onChange={handleChange} />

          <label htmlFor="password_confirmation">Confirm Password:</label>
          <input type="password" id="password_confirmation" name="password_confirmation" required minLength="8" onChange={handleChange} />

          <label htmlFor="positions_interested">Positions Interested:</label>
          <input type="text" id="positions_interested" name="positions_interested" required onChange={handleChange} />


          <label htmlFor="education">Education:</label>
          <input type="text" id="education" name="education" required onChange={handleChange} />

          <label htmlFor="resume">Resume:</label>
          <input type="file" id="resume" name="resume" required onChange={handleChange} />

          <label htmlFor="location">Location:</label>
          <input type="text" id="location" name="location" required onChange={handleChange} />

          <label htmlFor="ethnicity">Ethnicity:</label>
          <input type="text" id="ethnicity" name="ethnicity" required onChange={handleChange} />

          <label htmlFor="field_of_study">Field of Study:</label>
          <input type="text" id="field_of_study" name="field_of_study" required onChange={handleChange} />


          <label htmlFor="phone_number">Phone Number:</label>
          <input type="text" id="phone_number" name="phone_number" required onChange={handleChange} />


          <label htmlFor="research_experience">Research Experience:</label>
          <input type="text" id="research_experience" name="research_experience" required onChange={handleChange} />

          <label htmlFor="cv">CV:</label>
          <input type="text" id="cv" name="cv" required onChange={handleChange} />

          <label htmlFor="number_of_publications">Number of Publications:</label>
          <input type="text" id="number_of_publications" name="number_of_publications" required onChange={handleChange} />


          <input type="submit" value="Register" />
        </form>
      </div>
      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default CandidateRegister;
