import React, { useState } from "react";

const AcademiaRegister = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstname: "",
    description: "",
    name: "",
    positions: [],
    research_focus: "",
    education: "",
    resume: "",
    location: "",
    ethnicity: "",
    fod: "",
    researchexp: "",
    cv: "",
    Nopub: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordComplex, setIsPasswordComplex] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false); // Add this state variable

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validatePasswordComplexity = (password) => {
    const isComplex = password.length >= 8;
    setIsPasswordComplex(isComplex);
    return isComplex;
  };

  const handlePasswordChange = (event) => {
    const pass = event.target.value;
    setFormData((prevData) => ({ ...prevData, password: pass }));
    validatePasswordComplexity(pass);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleNumberOfPublicationsChange = (event) => {
    setFormData((prevData) => ({ ...prevData, Nopub: event.target.value.replace(/\D/, "") }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formData.password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (!isPasswordComplex) {
      alert("Password should be at least 8 characters long.");
      return;
    }

    fetch("https://mxm2896.uta.cloud/urmconnect_php/academia.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Registration successful!');
        // Handle the API response here if needed
        console.log(data);
      })
      .catch((error) => {
        alert('Registration failed: ' + error.message);

        console.error("Error submitting form:", error);
        // Handle error if necessary
      });

    console.log("Form submitted successfully!");
  };

  return (
    <div>
      <div className="new_jobposting_container">
        <h1>Academia Registration</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="Name">Name:</label>
          <input type="text" id="Name" name="name" required onChange={handleInputChange} value={formData.name} />

          <label htmlFor="Email">Email:</label>
          <input type="email" id="Email" name="email" required onChange={handleInputChange} value={formData.email} />
          {!isEmailValid && <p className="error">Invalid email address</p>}

          <label htmlFor="ph">Phone Number:</label>
          <input
            type="tel"
            id="ph"
            name="ph"
            required
            pattern="[0-9]{10}"
            onChange={handleInputChange}
            value={formData.ph}
          />
          {!isPhoneNumberValid && <p className="error">Invalid phone number</p>}

          <label htmlFor="academia_pass">Password:</label>
          <input
            type="password"
            id="academia_pass"
            name="password"
            required
            minLength="8"
            onChange={handlePasswordChange}
            value={formData.password}
          />

          <label htmlFor="academia_confpass">Confirm Password:</label>
          <input
            type="password"
            id="academia_confpass"
            name="confPassword"
            required
            minLength="8"
            onChange={handleConfirmPasswordChange}
            value={confirmPassword}
          />

          <label htmlFor="positions">Positions Interested:</label>
          <input
            type="text"
            id="positions"
            name="positions"
            required
            onChange={handleInputChange}
            value={formData.positions}
          />

          <label htmlFor="education">Education:</label>
          <input type="text" id="education" name="education" required onChange={handleInputChange} value={formData.education} />

          <label htmlFor="resume">Resume:</label>
          <input type="file" id="resume" name="resume" onChange={handleInputChange} value={formData.resume} />

          <label htmlFor="location">Location:</label>
          <input type="text" id="location" name="location" required onChange={handleInputChange} value={formData.location} />

          <label htmlFor="ethnicity">Ethnicity:</label>
          <input type="text" id="ethnicity" name="ethnicity" required onChange={handleInputChange} value={formData.ethnicity} />

          <label htmlFor="fod">Field of Study:</label>
          <input type="text" id="fod" name="fod" required onChange={handleInputChange} value={formData.fod} />

          <label htmlFor="researchexp">Research Experience:</label>
          <input
            type="text"
            id="researchexp"
            name="researchexp"
            required
            onChange={handleInputChange}
            value={formData.researchexp}
          />

          <label htmlFor="cv">CV:</label>
          <input type="text" id="cv" name="cv" required onChange={handleInputChange} value={formData.cv} />

          <label htmlFor="Nopub">Number of Publications:</label>
          <input
            type="text"
            id="Nopub"
            name="Nopub"
            required
            value={formData.Nopub}
            onChange={handleNumberOfPublicationsChange}
          />

          <input type="submit" value="Register" />
        </form>
      </div>
      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default AcademiaRegister;
