

import React from "react";
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";
  
const forgotemail = () => {
  return (
    <h1>Email sent successfully...</h1>
    
  );
};
  
export default forgotemail;