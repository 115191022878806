import React, { useEffect, useState } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, PieChart, Pie, Cell } from "recharts";

const MonitorUserActivity = () => {
  const [jobPostingData, setJobPostingData] = useState([]);
  const [appliedCandidatesData, setAppliedCandidatesData] = useState([]);
  const [userActivityData, setUserActivityData] = useState([]);
  const [userRoleData, setUserRoleData] = useState([]);
  const [applicantPositionData, setApplicantPositionData] = useState([]);

  useEffect(() => {
    // Fetch data from APIs and process it here
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoints
        const jobPostingResponse = await axios.get("https://mxm2896.uta.cloud/urmconnect_php/job_posting.php");
        const appliedCandidatesResponse = await axios.get("https://mxm2896.uta.cloud/urmconnect_php/applied_candidates.php");
        const userResponse = await axios.get("https://mxm2896.uta.cloud/urmconnect_php/custom_user.php");

        setJobPostingData(jobPostingResponse.data);
        setAppliedCandidatesData(appliedCandidatesResponse.data);

        // Process the data to get user activity statistics
        const userActivityStatistics = processUserActivityData(jobPostingResponse.data, appliedCandidatesResponse.data);
        setUserActivityData(userActivityStatistics);

        // Process the data to get user role statistics
        const userRoleStatistics = processUserRoleData(userResponse.data);
        setUserRoleData(userRoleStatistics);

        // Process the data to get applicant position statistics
        const applicantPositionStatistics = processApplicantPositionData(appliedCandidatesResponse.data);
        setApplicantPositionData(applicantPositionStatistics);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const processUserActivityData = (jobPostingData, appliedCandidatesData) => {
    // Process the data and calculate user activity statistics
    // Here, you can use the jobPostingData and appliedCandidatesData to calculate the user activity
    // For simplicity, let's assume user activity is the number of job postings and job applications per month.

    // Example data processing logic:
    const userActivityStatistics = [];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    for (let i = 0; i < 12; i++) {
      const month = i + 1;
      const monthData = {
        month: months[i],
        jobPostings: jobPostingData.filter(job => new Date(job.date).getMonth() === i).length,
        jobApplications: appliedCandidatesData.filter(application => new Date(application.application_date).getMonth() === i).length,
      };

      userActivityStatistics.push(monthData);
    }

    return userActivityStatistics;
  };

  const processUserRoleData = (userData) => {
    // Process the data and calculate user role statistics
    // Here, you can use the userData to calculate the number of users for each role.

    // Example data processing logic:
    const userRoleStatistics = userData.reduce((acc, user) => {
      if (user.role in acc) {
        acc[user.role]++;
      } else {
        acc[user.role] = 1;
      }
      return acc;
    }, {});

    return Object.entries(userRoleStatistics).map(([role, count]) => ({
      role,
      count,
    }));
  };

  const processApplicantPositionData = (appliedCandidatesData) => {
    // Process the data and calculate applicant position statistics
    // Here, you can use the appliedCandidatesData to calculate the number of applicants for each position type.

    // Example data processing logic:
    const applicantPositionStatistics = appliedCandidatesData.reduce((acc, applicant) => {
      if (applicant.position_type in acc) {
        acc[applicant.position_type]++;
      } else {
        acc[applicant.position_type] = 1;
      }
      return acc;
    }, {});

    return Object.entries(applicantPositionStatistics).map(([positionType, count]) => ({
      positionType,
      count,
    }));
  };

  // Color options for the PieChart
  const pieChartColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div>
      <h2>Placement Statistics</h2>
      {/* Bar Chart */}
      <div>
        <h3>Job Postings and Job Applications by Month</h3>
        <BarChart width={600} height={400} data={userActivityData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="jobPostings" fill="#8884d8" name="Job Postings" />
          <Bar dataKey="jobApplications" fill="#82ca9d" name="Job Applications" />
        </BarChart>
      </div>

      {/* Pie Chart 1 - User Roles */}
      <div>
        <h3>User Roles Distribution</h3>
        <PieChart width={400} height={400}>
          <Pie
            data={userRoleData}
            dataKey="count"
            nameKey="role"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label={(entry) => entry.role}
          >
            {userRoleData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      {/* Bar Chart - Applicant Positions */}
      <div>
        <h3>Applicants by Position Type</h3>
        <BarChart width={600} height={400} data={applicantPositionData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="positionType" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#82ca9d" name="Applicants" />
        </BarChart>
      </div>

      <div className="placementfooter">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default MonitorUserActivity;
