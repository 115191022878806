import React, { useState } from "react";

const AcademiaEditProfile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [researchFocus, setResearchFocus] = useState("");
  const [positionsOffered, setPositionsOffered] = useState("");
  const user_id = localStorage.getItem("user_id");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      description: "Academic with id " + user_id + " updated their profile.",
      name: name,
      bio: description,
      research_focus: researchFocus,
      positionsOffered: positionsOffered,
      user_id: user_id
    };

    const response = await fetch('https://mxm2896.uta.cloud/urmconnect_php/academia.php?user_id=' + user_id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      // The profile was updated successfully
      alert('Profile updated!');
    } else {
      // Something went wrong
      alert('Error updating profile.');
    }
  };

  return (
    <div class="academiaeditprofilealign">
      <div class="new_jobposting_container">
        <h1>Academia Profile</h1>
        <form onSubmit={handleSubmit}>
          <label for="Name">Name:</label>
          <input type="text" id="Name" name="Name" required onChange={e => setName(e.target.value)} />
          <label for="descrip">Description:</label>
          <input type="text" id="descrip" name="descrip" required onChange={e => setDescription(e.target.value)} />

          <label for="researchfocus">Research Focus:</label>
          <input type="text" id="researchfocus" name="researchfocus" required onChange={e => setResearchFocus(e.target.value)} />

          <label for="positionoffer">Positions Offered:</label>
          <input type="text" id="positionoffer" name="positionoffer" required onChange={e => setPositionsOffered(e.target.value)} />

          <input type="submit" value="Update" />
        </form>
      </div>
      <div class="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default AcademiaEditProfile;
