import React, { useEffect, useState } from "react";
import '../maincss.css'
import userEvent from "@testing-library/user-event";

const CandidateJobPost = () => {
  const [jobPostings, setJobPostings] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    // Fetch the API data and update the state
    fetch("http://mxm2896.uta.cloud/urmconnect_php/job_posting.php")
      .then((response) => response.json())
      .then((data) => setJobPostings(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleApply = (jid) => {
    // Create the request data
    const requestData = {
      user_id: user_id,
      jid: parseInt(jid),
      status: "Applied"
    };

    // Send the API request
    fetch("http://mxm2896.uta.cloud/urmconnect_php/job_application.php?user_id=" + user_id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data, for example, show a pop-up on success
        if (data.status === "success") {
          setShowPopup(true);
        } else {
          console.error("Application failed:", data.message);
        }
      })
      .catch((error) => console.error("Error applying for the job:", error));
  };

  return (
    <div>
      <h1>New Job Postings</h1>
      <div className="jobList">
        {jobPostings.map((job) => (
          <div className="jobCard" key={job.jid}>
            <h3>{job.job_description}</h3>
            <p>
              <strong>Location:</strong> {job.location}
            </p>
            <p>
              <strong>Position Type:</strong> {job.position_type}
            </p>
            <p>
              <strong>Date:</strong> {job.date}
            </p>
            <p>
              <strong>Applicants:</strong> {job.applicants_no}
            </p>
            <button onClick={() => handleApply(job.jid)}>Apply</button>
          </div>
        ))}
      </div>
      {showPopup && (
        <div className="popup">
          <p>Application Successful!</p>
          <button onClick={() => setShowPopup(false)}>Close</button>
        </div>
      )}
      <br />
      <div className="footer">
        <p>&copy; Group 6 2023 | Design and Development. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default CandidateJobPost;
